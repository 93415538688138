<template>
  <div class="tpi-view">
    <h4>TPI View for TPI ID: {{ tpiId }}</h4>

    <div v-if="tableData.length">
      <div class="table-responsive mt-3">
        <table class="table table-bordered">
          <tbody>
            <tr v-for="(row, rowIndex) in tableData" :key="rowIndex">
              <td>{{ verticalLabels[rowIndex] }}</td>
              <td v-for="(cell, cellIndex) in row" :key="`${rowIndex}-${cellIndex}`" :class="[cell.class, cell.topBorder]">
                {{ cell.value }}
              </td>
            </tr>
            <tr>
              <th></th>
              <!-- Display center frequencies only -->
              <th v-for="(value, valueIndex) in formattedHorizontalValues" :key="`value-${valueIndex}`">{{ value }}</th>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-else>
      <p>No zone data available for this TPI.</p>
    </div>
  </div>
</template>

<script>
import { reactive, ref, onMounted, computed } from 'vue';
import requests from '@/lib/requests';
import { getToken } from '@/lib/auth';

export default {
  name: 'TPIUserView',
  props: {
    tpiId: {
      type: Number,
      required: true
    },
    userId: {
      type: Number,
      required: true
    }
  },
  setup(props) {
    const verticalLabels = [
      'V16', 'V15', 'V14', 'V13', 'V12', 'V11', 'V10', 'V9', 'V8', 'V7', 'V6', 'V5', 'V4', 'V3', 'V2', 'V1'
    ];

    const tableData = reactive(Array.from({ length: 16 }, () =>
      Array.from({ length: 16 }, () => ({ value: '', class: 'red', topBorder: '' }))
    ));

    const errorMessage = ref('');
    const centerFrequencies = ref([]);

    const formattedHorizontalValues = computed(() => 
      centerFrequencies.value.map(freq => freq >= 1000 ? `${freq / 1000}K` : freq.toString())
    );

    const fetchTPIData = async () => {
      try {
        const response = await requests.get(`/tpi/view/user-tpi/${props.userId}/${props.tpiId}`, {}, getToken());
        if (response && response.data) {
          if (response.data.grid_config && response.data.grid_config.center_frequencies) {
            centerFrequencies.value = response.data.grid_config.center_frequencies;
          } else {
            errorMessage.value = 'Center frequencies data not found in the grid configuration.';
          }
          processActions(response.data.band_data);
        } else {
          errorMessage.value = 'No TPI data found.';
        }
      } catch (error) {
        console.error(error);
        errorMessage.value = 'An error occurred while fetching TPI data.';
      }
    };

    const processActions = (bandData) => {
      bandData.forEach(band => {
        if (band.min !== undefined && band.max !== undefined) {
          placeGreenZone(band.band_index, band.min, band.max);
          handleBelowMin(band.band_index, band.min, band.inaudible);
          handleAboveMax(band.band_index, band.max, band.painful);
        }
        band.sensible_zones.forEach(zone => {
          placeSensibleZone(band.band_index, zone.loudest_volume, zone.quietest_volume);
        });
      });
    };

    const placeGreenZone = (bandIndex, minVolume, maxVolume) => {
      if (bandIndex >= 1 && bandIndex <= 16 && minVolume >= 1 && maxVolume <= 16) {
        const cellIndex = bandIndex - 1;
        for (let y = minVolume; y <= maxVolume; y++) {
          const rowIndex = 16 - y;
          tableData[rowIndex][cellIndex] = { value: '', class: 'green', topBorder: '' };
        }
      }
    };

    const handleBelowMin = (bandIndex, minVolume, inaudibleVolume) => {
      if (bandIndex >= 1 && bandIndex <= 16 && inaudibleVolume >= 1 && inaudibleVolume <= 16) {
        const cellIndex = bandIndex - 1;
        for (let y = 1; y < minVolume; y++) {
          const rowIndex = 16 - y;
          const color = y < inaudibleVolume ? 'below-inaudible' : 'inaudible';
          tableData[rowIndex][cellIndex] = { value: '', class: color, topBorder: '' };
        }
      }
    };

    const handleAboveMax = (bandIndex, maxVolume, painfulVolume) => {
      if (bandIndex >= 1 && bandIndex <= 16 && painfulVolume >= 1 && painfulVolume <= 16) {
        const cellIndex = bandIndex - 1;
        for (let y = maxVolume + 1; y <= 16; y++) {
          const rowIndex = 16 - y;
          const color = y <= painfulVolume ? 'above-max' : 'painful';
          tableData[rowIndex][cellIndex] = { value: '', class: color, topBorder: '' };
        }
      }
    };

    const placeSensibleZone = (bandIndex, loudestVolume, quietestVolume) => {
      if (bandIndex >= 1 && bandIndex <= 16) {
        const cellIndex = bandIndex - 1;
        for (let y = quietestVolume; y <= loudestVolume; y++) {
          const rowIndex = 16 - y;
          tableData[rowIndex][cellIndex] = { 
            value: '', 
            class: 'sensible', 
            topBorder: y === quietestVolume ? 'sensible-border-top' : '' 
          };
        }
      }
    };

    onMounted(() => {
      fetchTPIData();
    });

    return {
      verticalLabels,
      formattedHorizontalValues,
      tableData,
      errorMessage
    };
  }
};
</script>

<style scoped>
.table-bordered {
  width: 100%;
  margin: 0;
  padding: 0;
  table-layout: fixed;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
  text-align: center;
  vertical-align: middle;
  position: relative;
  width: 6.25%;
}

.green {
  background-color: #12b76a;
}

.inaudible {
  background-color: #98a2b3;
}

.below-inaudible {
  background-color: #667184;
}

.above-max {
  background-color: #febe83;
}

.painful {
  background-color: #f87166;
}

.sensible {
  background-color: #6073f3;
  color: white;
}

.sensible-border-top {
  border-top: 3px solid #6073f3 !important;
}

.white {
  background-color: white;
}

.bold {
  font-weight: bold;
}
</style>
